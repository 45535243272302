/* Arancione ffb733 */
#crop-modal[data-v-3ce8456f] {
    max-height: 80%;
    top: 5% !important;
}
.container-foto-profilo[data-v-3ce8456f] {
    width: 100%;
    position: relative;
    height: 70px;
    background-image: url('../assets/ondina.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.bordo-foto-profilo[data-v-3ce8456f] {
    width: 80px;
    height: 80px;
    border: 3px solid #fff;
    border-radius: 40px;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
}
.bordo-foto-profilo > img[data-v-3ce8456f] {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    object-fit: cover;
}
.bordo-foto-profilo .fotocamera[data-v-3ce8456f] {
    width: 25px;
    height: 25px;
    line-height: 30px;
    bottom: -3px;
    right: -3px;
}
#upload-foto-profilo[data-v-3ce8456f] {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    z-index: 2;
}
.profilo-main[data-v-3ce8456f] {
    margin-top: 50px;
}
.profilo-main h3[data-v-3ce8456f] {
    color: #ffb733;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}
.profilo-main h4[data-v-3ce8456f] {
    color: #aaaaaa;
    margin-top: 0;
    text-align: center;
}
.profilo-main .tipologia[data-v-3ce8456f] {
    background-color: #ffb733;
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 13px;
    margin: 0 auto;
    border-radius: 13px;
    min-width: 180px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding-left: 4px;
    padding-right: 4px;
}
.profilo-main .tipologia.concorso[data-v-3ce8456f] {
    margin-top: 10px;
}
.menu-profilo[data-v-3ce8456f] {
    margin-top: 20px;
    margin-bottom: 100px;
}
.menu-profilo li[data-v-3ce8456f] {
    display: block;
    height: 45px;
    line-height: 45px;
    color: #1bb5c1;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    border-bottom: 1px solid #e7e7e7;
    text-align: left;
    margin: 0 20px;
}
.menu-profilo li a[data-v-3ce8456f] {
    color: #1bb5c1;
    display: block;
    width: 100%;
    height: 100%;
}
.menu-profilo li img[data-v-3ce8456f] {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    width: 24px;
}