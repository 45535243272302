.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

:root {
  --app-height: 100%;
}

@media not all and (hover:hover) {
  height: 100%;
  height: var(--app-height);
}

* {
  font-family: 'Muli', sans-serif;
}
.home-container {
  margin-top: 10px;
}

.progress {
  margin-top: 0;
}

.main-logo {
  width: 120px;
  margin: 25px 0;
}

.logo-container {
  border-bottom: 3px solid #f7f4fb;
  margin-bottom: 7px;
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.standard-link {
  color: #1bb5c1;
  text-decoration: none;
}

.full-view {
  width: 100%;
  position: relative;
  height: calc(100vh - 56px);
  float: left;
}

.bottom-tabs {
  position: absolute;
  bottom: 0;
}

.tab-view {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
}

.slide-enter-active {
  transition: all .3s ease .2s;
}

.slide-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-enter, .slide-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.app-view {
  height: calc(100vh - 50px);
  text-align: left;
  position: relative;
  overflow: auto;
}

nav a.sidenav-trigger {
  display: block !important;
}

.tabs .tab a:hover, .tabs .tab a.active, .tabs .tab a {
  color: #0d7b6b;
}

.tabs .indicator, nav {
  background-color: #0d7b6b;
}

.tabs .tab a:focus, .tabs .tab a:focus.active {
  background-color: rgb(13,123,107, 0.2);
}

.input-styled input,
.input-styled select,
.input-styled textarea {
  background-color: #f7f7f7;
  border-radius: 4px;
  color: #898096;
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
  border: 0;
  width: 100%;
  margin: 8px 0;
  outline: 0;
  -webkit-appearance:none;
}

.input-styled label {
  text-align: left;
  display: block;
  margin-top: 15px;
}

.home-tab {
  padding-top: 25px;
}

.tabs {
  height: 32px;
}

.tabs .indicator, nav {
  background-color: #1bb5c1;
}

.tabs .tab a:hover, .tabs .tab a.active, .tabs .tab a {
  color: #1bb5c1;
  text-transform: none;
  text-transform: initial;
}

.tabs .tab {
  height: 32px;
  line-height: 32px;
}

.btn:hover, .btn-large:hover, .btn-small:hover, .btn {
  background-color: #1bb5c1;
  font-size: 14px;
  text-transform: none;
  text-transform: initial;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 5px;
}

.tabs .tab a:focus, .tabs .tab a:focus.active {
  background-color: transparent;
}

.tabs .tab a {
  color: #898989;
}

.btn.btn-flat {
  background-color: #fff;
  color: #1bb5c1;
  border: 2px solid #1bb5c1;
}

.tipo-account.active .cls-2,
.tipo-account.active .cls-3 {
  fill: #3a6899;
}

.tipo-account .cls-2,
.tipo-account .cls-3 {
  fill: #8e8ba5;
}

.account-types .tipo-account.active .contenitore {
  border: 2px solid #3a6899;
}

.tipo-account .cls-1{
  opacity:0;
}

.tipo-account .cls-3{
  opacity:0.5;
  isolation:isolate;
}

.account-types .tipo-account {
  width: 90px;
  height: auto;
}

.account-types .tipo-account .contenitore {
  width: 100%;
  height: 90px;
  border: 2px solid #999;
}

.account-types {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
}

.account-types svg {
  height: 76px;
  margin-top: 4px;
}

.tipo-account span {
  color: #999;
}

.tipo-account {
  position: relative;
}

.tipo-account .tick {
  position: absolute;
  top: 5px;
  right: 5px;
}

.tipo-account .tick img {
  width: 20px;
  height: 20px;
}

.descrizione-account {
  margin: 35px auto 0 auto;
  width: 300px;
}

.descrizione-account p {
  font-size: 14px;
  line-height: 140%;
  text-align: left;
  color: #888;
}

.btn.opaque {
  opacity: 0;
}

.progress {
  background-color: #fff;
}

.progress .determinate {
  background-color: #1bb5c1;
}

.logo-blue {
  color: #3a6899 !important;
  text-align: center;
}

h5.subhead {
  font-size: 15px;
  font-weight: bold;
  color: #1bb5c1;
  margin: 30px auto;
}

.testo-descrittivo {
  width: 300px;
  font-size: 14px !important;
  margin: 30px auto 0 auto;
}

.foto-profilo {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 30px auto;
  overflow: hidden;
}

.foto-profilo input {
  position: absolute;
  opacity: 0;
  z-index: 2;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

.foto-profilo .cerchio-foto {
  width: 180px;
  height: 180px;
  border-radius: 90px;
  border: 2px solid #3a6899;
  overflow: hidden;
}

.bordo-foto-profilo .fotocamera,
.foto-profilo .fotocamera {
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #1bb5c1;
  border-radius: 18px;
  line-height: 47px;
  text-align: center;
  z-index: 1;
}

.foto-profilo .fotocamera.elimina {
  z-index: 4;
  background-color: #fc0058;
}

.bordo-foto-profilo .fotocamera img,
.foto-profilo .fotocamera img {
  width: 70%;
}

.foto-profilo .uploaded {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading.fixed {
  position: fixed;
}

h4 {
  font-size: 15px;
  font-weight: bold;
}

.btn.btn-fullwidth {
  width: 100%;
}

.dettagli-attivita {
  line-height: 160%;
  text-align: left;
  color: #8e8e8e;
}

.dettagli-attivita img {
  display: inline-block;
  width: 18px;
  vertical-align: text-top;
  margin-right: 5px;
}

canvas {
  position: fixed;
  pointer-events: none;
  opacity: 0;

}

.relativo {
  position: relative;
}

#autoComplete_list:empty {
  display: none;
}

#autoComplete_list {
  border: 1px solid #d8d8d8;
  border-bottom: 0px solid #000;
}

.autoComplete_result {
  padding: 10px;
  border-bottom: 1px solid #d8d8d8;
}

.indietro {
  position: absolute;
  left: 15px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.indietro img {
  width: 20px;
  display: inline;
  vertical-align: middle;
}

.modal {
  -webkit-overflow-scrolling: touch !important;
}

.uniupo {
  text-align: center;
  margin-bottom: -25px;
}

.uniupo img {
  width: 230px;
  display: inline-block;
}

body {
  overflow: auto !important;
}
