h2[data-v-ef445069] {
    margin: 0;
    font-size: 36px;
    color: #1bb5c1;
    margin-bottom: 40px;
}
.page[data-v-ef445069] {
    padding-top: 5px;
    display: none;
    padding-bottom: 50px;
}
.page.active[data-v-ef445069] {
    display: block;
}
.nobottom[data-v-ef445069] {
    margin-bottom: 10px;
}