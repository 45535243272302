#concorsi[data-v-79a1bbf9] {
    margin-top: 20%
}
.scegli-concorso[data-v-79a1bbf9] {
    margin-top: 20%
}
img[data-v-79a1bbf9] {
    width: 60%;
    display: block;
    margin: 60px auto;
}
p[data-v-79a1bbf9] {
    font-size: 17px;
    color: #999;
    margin-bottom: 50px;
}
.btn[data-v-79a1bbf9], select[data-v-79a1bbf9] {
    margin-bottom: 12px;
}
.iren-banner[data-v-79a1bbf9] {
    /* background: url('https://www.atenalucegas.it/theme/atena_tra/img/new/logo-2020.png')*/
    background-size: cover ;
    position: fixed;
    width: 290px;
    height: 86px;
    left: 50%;
    transform: translate(-50%, 0);
    margin-left: auto;
    margin-right: auto;
    bottom: 50px;
}
.banner-img[data-v-79a1bbf9]  {
    position: absolute;
    max-height: 100%;
    width: auto;
    margin: 0px;
}