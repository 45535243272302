h3[data-v-7d6f67de], p[data-v-7d6f67de] {
    color: #777;
    font-size: 17px;
    text-align: center;
}
h4[data-v-7d6f67de] {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}
p[data-v-7d6f67de] {
    margin-bottom: 40px;
}
h2[data-v-7d6f67de] {
    margin: 0;
    font-size: 36px;
    color: #1bb5c1;
    margin-bottom: 40px;
    text-align: center;
}
.page[data-v-7d6f67de] {
    padding-top: 5px;
    display: none;
    padding-bottom: 50px;
}
.page.active[data-v-7d6f67de] {
    display: block;
}
.nobottom[data-v-7d6f67de] {
    margin-bottom: 10px;
}
[type="checkbox"]+span[data-v-7d6f67de]:not(.lever),
[type="radio"]:not(:checked)+span[data-v-7d6f67de],
[type="radio"]:checked+span[data-v-7d6f67de] {
    line-height: 120%;
    font-size: 14px;
    color: #888;
}
.mleftmin[data-v-7d6f67de] {
    margin-left: -2px;
}
.privacy[data-v-7d6f67de] {
    position: relative;
}
.privacy-overlay[data-v-7d6f67de] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
}