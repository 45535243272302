.gratta-vinci[data-v-1fb1d07a] {
    width: 100%;
    height: 375px;
    background-color: #1bb5c1;
    padding-top: 15px;
}
.gratta-card[data-v-1fb1d07a] {
    width: 310px;
    height: 345px;
    background-color: #fff;
    border-radius: 5px;
    margin: 0 auto;
    box-shadow: 1px 1px 5px 0px rgba(160,160,160,1);
}
.gratta-top[data-v-1fb1d07a] {
    background-color: #3a6899;
    width: 100%;
    border-radius: 5px 5px 0 0;
    height: 35px;
    line-height: 35px;
    color: #fff;
    text-align: center;
    position: relative;
}
.gratta-top span[data-v-1fb1d07a] {
    font-weight: bold;
    font-size: 14px;
}
.gratta-top img[data-v-1fb1d07a] {
    position: absolute;
    top: 6px;
    left: 10px;
    height: 23px;
}
.gratta-content[data-v-1fb1d07a] {
    width: 310px;
    height: 310px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
}
.gratta-content .animation-container[data-v-1fb1d07a] {
    width: 82px;
    height: 82px;
    margin: 6px 0;
    display: block;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}
.gratta-content .animation-container img[data-v-1fb1d07a],
.mid[data-v-1fb1d07a] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.fore[data-v-1fb1d07a] {
    z-index: 3;
    transition: all 0.3s linear;
}
.gratta-content .animation-container.anima .fore[data-v-1fb1d07a] {
    transform: rotate(180deg) scale(0.1);
    opacity: 0;
}
.mid[data-v-1fb1d07a] {
    z-index: 2;
    background-color: #fff;
}
.back[data-v-1fb1d07a] {
    z-index: 1;
}
p[data-v-1fb1d07a] {
    width: 300px;
    line-height: 140%;
    color: #aaa;
    margin: 20px auto 0 auto;
    display: block;
    text-align: center;
}
.vinti-grattati[data-v-1fb1d07a] {
    color: #1bb5c1;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}
.vinti-grattati-2[data-v-1fb1d07a] {
    color: #1bb5c1;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin: 0 auto;
}
.btn[data-v-1fb1d07a] {
    /*position: fixed;
    bottom: 65px;
    right: 15px;
    left: 15px;*/
    width: 300px !important;
    margin-top: 20px;
}
.padded[data-v-1fb1d07a] {
    padding-bottom: 60px;
}
.win-car-image[data-v-1fb1d07a] {
    width: 120px;
    margin-top: 15px;
}
.monopattino-vinto[data-v-1fb1d07a] {
    max-width: 50%;
    max-height: 50%;
    margin: 0 auto;
}
.buono-vinto[data-v-1fb1d07a] {
    max-width: 50%;
    max-height: 50%;
    margin: 0 auto;
}
.buono-vinto-2[data-v-1fb1d07a] {
    max-height: 100%;
    margin: 0 auto;
}
.vinti-monopattino[data-v-1fb1d07a] {
    font-size: 12pt;
}
.monopattino-iren[data-v-1fb1d07a] {
    margin: 0 auto;
    margin-right: 0px;
    position: relative;
    max-width: 88%;
}
.buono-iren[data-v-1fb1d07a] {
    margin: 0 auto;
    margin-right: 0px;
    position: relative;
    max-width: 88%;
}
.testo-iren[data-v-1fb1d07a] {
    position: relative;
    margin-bottom: -10px;
    z-index: 1000;
    margin-top: 10px;
}
.monopattino-sponsor[data-v-1fb1d07a] {
    animation: fadein 2s;
}
.buono-sponsor[data-v-1fb1d07a] {
    animation: fadein 2s;
}