.input-styled label[data-v-bc658705] {
    margin-top: 10px;
}
.row[data-v-bc658705] {
    margin-left: 10px;
    margin-right: 10px;
}
ul[data-v-bc658705] {
    text-align: left;
}
ul li[data-v-bc658705] {
    color: red;
}