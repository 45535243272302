.cropper-container[data-v-41162f34] {
     margin: 0 auto 15px auto;
     position: relative;
}
.square[data-v-41162f34] {
    position: relative;
    overflow: hidden;
    background-color: #ffffff;
    margin: 0 auto;
    /*max-width: 80%;*/
}
.square .mask[data-v-41162f34] {
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 0 0 2000px rgba(255, 255, 255 ,0.8);
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 100%;
}
.square img[data-v-41162f34] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.square img.portrait[data-v-41162f34] {
    width: 100%;
}
.square img.landscape[data-v-41162f34] {
    height: 100%;
}
.logo-blue[data-v-41162f34] {
    color: #1bb5c1;
    margin-bottom: 15px;
    display: block;
    font-size: 17px;
}
.image-cropper[data-v-41162f34] {
    width: 100%;
    padding: 20px 0;
    margin: 10px 0;
    background-color: #ffffff;
}
.btn[data-v-41162f34] {
    display: block;
    max-width: 190px;
    margin: 0 auto 10px auto;
}
.btn-row[data-v-41162f34] {
    display: inline-block;
}
