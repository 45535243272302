.search-bar[data-v-19bae86b] {
    position: fixed;
    top: 15px;
    left: 15px;
    right: 15px;
    z-index: 5;
    height: 50px;
}
.search-bar input[data-v-19bae86b] {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 6px;
    border: 0px solid #999;
    background-color: #fff !important;
    padding: 0 40px 0 10px;
    outline: 0;
    box-shadow: 1px 1px 5px 0px rgba(210,210,210,1);
    -webkit-appearance: none;
    -moz-appearance: none;
}
.search-bar img[data-v-19bae86b] {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
}