.azioni[data-v-15ced13e] {
    background-color: #1bb5c1;
    min-height: calc(100vh - 50px);
    min-height: -webkit-calc(100vh - 50px);
}
.container[data-v-15ced13e] {
    padding-top: 20px;
    padding-bottom: 50px;
}
.card-azione[data-v-15ced13e] {
    display: block;
    margin-bottom: 20px;
    width: 100%;
    height: 120px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 1px 1px 5px 0px rgba(160,160,160,1);
}
.immagine-azione[data-v-15ced13e] {
    width: 80px;
    height: 120px;
    line-height: 120px;
    float: left;
    text-align: center;
    border-right: 2px dotted #858585;
}
.immagine-azione img[data-v-15ced13e] {
    width: 60px;
    display: inline;
    vertical-align: middle;
}
.descrizione-azione[data-v-15ced13e] {
    min-width: 200px;
    float: left;
    height: 120px;
    padding-left: 10px;
    text-align: left;
    position: relative;
}
.descrizione-azione .centratore[data-v-15ced13e] {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 190px;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
}
.descrizione-azione h3[data-v-15ced13e] {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 10px 0;
    color: #3a6899;
}
.descrizione-azione p[data-v-15ced13e] {
    font-size: 13px;
    line-height: 140%;
    color: #999999;
    margin-bottom: 0;
}