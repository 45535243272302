.bottom-bar[data-v-71f486d5] {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    padding: 0 15px;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
}
.btn[data-v-71f486d5] {
    height: 32px;
    line-height: 32px;
    width: 85px;
}
.btn.btn-flat[data-v-71f486d5] {
    line-height: 30px;
}
.pagination[data-v-71f486d5] {
    font-size: 16px;
    letter-spacing: 1px;
    color: #777;
    width: auto;
}