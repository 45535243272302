img[data-v-f6d10a2a] {
    width: 100%;
    display: block;
    margin: 0px auto 30px auto;
}
p[data-v-f6d10a2a] {
    font-size: 17px;
    color: #999;
    margin-bottom: 30px;
    padding: 0 10px;
}
.btn.btn-fullwidth[data-v-f6d10a2a] {
    width: 300px;
}
.standard-link[data-v-f6d10a2a] {
    margin-top: 20px;
    display: inline-block;
}
.relativo[data-v-f6d10a2a] {
    height: 100%;
    overflow: auto;
    margin-bottom: 100px;
}