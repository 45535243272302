img[data-v-b0f88cf8] {
    width: 60%;
    display: block;
    margin: 60px auto;
}
p[data-v-b0f88cf8] {
    font-size: 17px;
    color: #999;
    margin-bottom: 50px;
}
.btn[data-v-b0f88cf8] {
    position: fixed;
    bottom: 65px;
    right: 15px;
    left: 15px;
    width: auto !important;
}