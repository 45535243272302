.giorni[data-v-292fc69d] {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: space-between;
}
.giorno[data-v-292fc69d] {
    width: 35px;
    height: 35px;
    line-height: 33px;
    color: #888;
    font-size: 12px;
    text-align: center;
    border: 1px solid #ccc;
}
.giorno.attivo[data-v-292fc69d] {
    color: #fff;
    background-color: #1bb5c1;
    border: 1px solid #1bb5c1;
}
.flexer-ailati[data-v-292fc69d] {
    width: 100%;
    height: 25px;
    line-height: 25px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 15px;
    color: #999;
}
.orario-container[data-v-292fc69d] {
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}
.elimina[data-v-292fc69d] {
    color: #ff3c83;
    font-weight: bold;
}
.paddato > label[data-v-292fc69d] {
    margin-top: 15px;
}
.paddato > label > span[data-v-292fc69d] {
    font-size: 12px;
}
.clearfix[data-v-292fc69d] {
    clear: both;
}