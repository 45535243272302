.bazar-attivita[data-v-5e98a976] {
    width: 80px;
}
.dot-divider[data-v-5e98a976] {
    width: 100%;
    height: 1px;
    border-bottom: 1px dotted #000000;
}
.visualizza-mappa[data-v-5e98a976] {
    display: block;
    margin: 15px 0;
}
.altezza-buono[data-v-5e98a976] {
    width: 100%;
    height: 280px;
}
h4[data-v-5e98a976] {
    text-transform: uppercase;
    color: #1bb5c1;
    margin-bottom: 2px;
}
h5[data-v-5e98a976] {
    text-transform: uppercase;
    font-size: 17px;
}
#qr-code[data-v-5e98a976] {
    width: 140px;
    height: 140px;
    margin-bottom: 10px;
}
.data-buono[data-v-5e98a976] {
    margin-bottom: 15px;
    display: block;
    color: #999;
}
.titolo-esercente[data-v-5e98a976] {
    color: #999;
    margin-top: 15px;
}
.sottotitolo-esercente[data-v-5e98a976] {
    color: #999;
    margin-top: 15px;
    margin-bottom: 15px;
}
.buono-vinto-2[data-v-5e98a976] {
    max-width: 50%;
    margin: 0 auto;
}
.pre-formatted[data-v-5e98a976] {
    white-space: pre;
}
.concorso-scaduto[data-v-5e98a976] {
    color: red;
}