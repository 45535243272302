.container-copertina[data-v-ebb984a1] {
    width: 100%;
    height: 120px;
    margin-top: 8px;
}
.container-copertina .background[data-v-ebb984a1] {
    background-color: #738fb9;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
}
.container-copertina .background input[data-v-ebb984a1],
.blocco-galleria input[data-v-ebb984a1] {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 3;
}
.fotocamera[data-v-ebb984a1] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 25px;
    background-color: #3b5d9b;
    text-align: center;
}
.fotocamera img[data-v-ebb984a1] {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-top: 9px;
    z-index: 3;
}
.elimina-copertina[data-v-ebb984a1] {
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #1bb5c1;
    border-radius: 18px;
    line-height: 47px;
    text-align: center;
    z-index: 1;
}
.elimina-copertina[data-v-ebb984a1] {
    z-index: 4;
    background-color: #fc0058;
}
.elimina-copertina img[data-v-ebb984a1] {
    width: 70%;
}
.blocco-galleria[data-v-ebb984a1] {
    width: 65px;
    height: 65px;
    position: relative;
    float: left;
    margin-right: 10px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    margin-bottom: 10px;
}
.blocco-galleria > img[data-v-ebb984a1] {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.flexer[data-v-ebb984a1] {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-top: 10px;
    flex-wrap: wrap;
}
.elimina-galleria[data-v-ebb984a1] {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: -8px;
    right: -8px;
    background-color: #1bb5c1;
    border-radius: 18px;
    line-height: 37px;
    text-align: center;
    z-index: 4;
    background-color: #fc0058;
}
.elimina-galleria img[data-v-ebb984a1] {
    width: 80%;
}