.no-buoni[data-v-ad886ce0]{
    border-radius: 7px;
    box-shadow: 1px 1px 5px 0px rgba(150,150,150,1);
    background-color: #fff;
    margin-bottom: 15px;
    height: 50px;
    text-align: center;
    padding-top: 13px;
    width: 100%;
}
.page-completa[data-v-ad886ce0] {
    width: 100%;
    height: calc(100vh - 50px);
    height: -webkit-calc(100vh - 50px);
}
.gratta-tab[data-v-ad886ce0] {
    width: 100%;
    height: calc(100% - 69px);
    height: -webkit-calc(100% - 69px);
    background-color: #1bb5c1;
    margin-top: 5px;
    overflow: auto;
    -webkit-overflow-scrolling: touch !important;
}
.flexer-grattavinci[data-v-ad886ce0] {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.flexer-grattavinci[data-v-ad886ce0]::after {
    content: "";
    width: 90px;
}
.flexer-grattavinci img[data-v-ad886ce0] {
    width: 30%;
    margin-bottom: 10px;
}
.tabs[data-v-ad886ce0] {
    height: 64px;
}
.tabs .tab[data-v-ad886ce0] {
    height: 64px;
    line-height: 17px;
    padding-top: 10px;
    font-weight: bold;
    width: 33%;
}
.tabs .tab-2[data-v-ad886ce0] {
    height: 64px;
    line-height: 22px;
    padding-top: 10px;
    font-weight: bold;
    width: 50%;
}
.card-buono[data-v-ad886ce0] {
    width: 30%;
    height: 156px;
    border-radius: 7px;
    box-shadow: 1px 1px 5px 0px rgba(150,150,150,1);
    background-color: #fff;
    margin-bottom: 15px;
}
.top-card[data-v-ad886ce0],
.middle-card[data-v-ad886ce0] {
    width: 100%;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-bottom: 1px dashed #666;
}
.middle-card.barrata[data-v-ad886ce0] {
    text-decoration: line-through;
    color: #999;
}
.top-card[data-v-ad886ce0] {
    height: 52px;
    line-height: 52px;
}
.bottom-card[data-v-ad886ce0] {
    width: 100%;
    height: 62px;
    text-align: center;
    color: #999;
    font-size: 12px;
    font-weight: bold;
    position: relative;
}
.vcentering[data-v-ad886ce0] {
    position: absolute;
    top: 50%;
    left: 5px;
    right: 5px;
    line-height: 140%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
}
.bottom-card img[data-v-ad886ce0] {
    width: 30%;
    margin: 4px auto 0 auto;
    display: block;
}
.top-card[data-v-ad886ce0] {
    color: #1bb5c1;
    font-size: 13px;
    font-weight: bold;
}
.middle-card b[data-v-ad886ce0] {
    font-size: 22px;
}
.middle-card[data-v-ad886ce0] {
    font-size: 16px;
    color: #3a6899;
}
.monopattino[data-v-ad886ce0],.bicicletta[data-v-ad886ce0] {
    width: 4rem !important;
    margin-top: 15px;
}
.modal[data-v-ad886ce0] {
    max-height: none;
    height: 460px;
}
.nome-utilizzatore[data-v-ad886ce0] {
    font-size: 8px;
}
#singolo-qr[data-v-ad886ce0] {
    height: 90% !important;
    width: 90% !important;
    top: 5% !important;
}
#singolo-qr-2[data-v-ad886ce0] {
    height: 90% !important;
    width: 90% !important;
    top: 5% !important;
}