.contatore[data-v-f96e1e18] {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: .8rem;
    color: #9e9e9e;
}
.input-styled[data-v-f96e1e18] {
    position: relative;
}
.contatore.color-red[data-v-f96e1e18] {
    color: red;
}
textarea[data-v-f96e1e18] {
    padding-top: 5px;
    line-height: 150%;
}