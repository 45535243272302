.btn[data-v-d06f086c] {
    margin-top: 30px;
    width: 94%;
}
.margin-top[data-v-d06f086c] {
    margin-top: 22px !important;
    text-align: center;
}
.tabs a[data-v-d06f086c] {
    text-align: center;
}