.vetrina h1[data-v-eb720cc0] {
    font-size: 14px;
    font-weight: bold;
    color: #1bb5c1;
    margin: 20px 0 30px 0;
}
.copertina[data-v-eb720cc0] {
    width: 100%;
    height: 160px;
    margin: 10px 0 30px 0;
    background-size: cover;
    background-position: center center;
}
.dettagli-attivita[data-v-eb720cc0] {
    padding-bottom: 18px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 18px;
    line-height: 200%;
}
p[data-v-eb720cc0] {
    color: #8e8e8e;
    text-align: left;
}
.indietro[data-v-eb720cc0] {
    position: absolute;
    top : -14px;
    left: 15px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}
.indietro img[data-v-eb720cc0] {
    width: 20px;
    display: inline;
    vertical-align: middle;
}
.container[data-v-eb720cc0] {
    margin-bottom: 100px;
    overflow: auto;
    heigth: 100%;
}