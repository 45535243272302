.locale[data-v-14d5bfaa] {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
    padding-bottom: 10px;
    text-align: left;
}
.locale h1[data-v-14d5bfaa] {
    font-size: 14px;
    font-weight: bold;
    color: #1bb5c1;
    margin: 0 0 10px 0;
}
.container[data-v-14d5bfaa] {
    margin-top: 75px;
}
.locale .immagine img[data-v-14d5bfaa] {
    width: 65px;
    height: 65px;
    border-radius: 8px;
    object-fit: cover;
    position: relative;
    overflow: hidden;
}
.locale .immagine img[data-v-14d5bfaa]:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/bazar.png');
    background-size: cover;
    background-color: #fff;
}
.locale .col.s3[data-v-14d5bfaa] {
    margin: 0;
    padding: 0;
}
.locale .col.s9[data-v-14d5bfaa] {
    color: #999;
    font-size: 13px;
    padding-top: 4px;
}
.container[data-v-14d5bfaa] {
    position: relative;
    min-height: 360px;
    padding-bottom: 60px;
}
.no-locali[data-v-14d5bfaa] {
    border-radius: 7px;
    box-shadow: 1px 1px 5px 0px rgba(150,150,150,1);
    background-color: #fff;
    margin-bottom: 15px;
    height: 50px;
    text-align: center;
    padding-top: 13px;
    width: 100%;
}