p[data-v-30378f86] {
    font-size: 17px;
    color: #999;
    margin-bottom: 20px;
}
.webcam-view[data-v-30378f86] {
    width: 280px;
    height: 280px;
    background-color: #1bb5c1;
    margin: 0 auto;
    position: relative;
    /*border: 1px solid #e8e8e8;*/
}
.indietro[data-v-30378f86] {
    position: absolute;
    top : -14px;
    left: 15px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}
.indietro img[data-v-30378f86] {
    width: 20px;
    display: inline;
    vertical-align: middle;
}
.input-styled.label-centrata label[data-v-30378f86] {
    text-align: center;
}
.input-styled.label-centrata[data-v-30378f86] {
    margin-bottom: 10px;
}
.container[data-v-30378f86] {
    padding-bottom: 50px;
}
.btn[data-v-30378f86] {
    margin-top: 10px;
}
.box-scontrino[data-v-30378f86] {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 140px;
    height: 140px;
    /*border: 1px solid #ffffff;*/
    background-color: #3a6899;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 1;
}
.box-scontrino img[data-v-30378f86] {
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}
.webcam-view input[data-v-30378f86] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}
.webcam-view .box-scontrino img[data-v-30378f86] {
    width: 80%;
}
.webcam-view > img[data-v-30378f86] {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.no-concorsi img[data-v-30378f86] {
    width: 60%;
    display: block;
    margin: 60px auto;
}
.no-concorsi p[data-v-30378f86] {
    font-size: 17px;
    color: #999;
    margin-bottom: 15px;
}