p[data-v-29d02542] {
    font-size: 17px;
    color: #999;
    margin-bottom: 20px;
}
.webcam-view[data-v-29d02542] {
    width: 320px;
    height: 320px;
    background-color: #1bb5c1;
    margin: 0 auto;
}
.indietro[data-v-29d02542] {
    position: absolute;
    top : 6px;
    left: 0px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}
.indietro img[data-v-29d02542] {
    width: 20px;
    display: inline;
    vertical-align: middle;
}
.input-styled.label-centrata label[data-v-29d02542] {
    text-align: center;
}
.input-styled.label-centrata[data-v-29d02542] {
    margin-bottom: 20px;
}
.scroller[data-v-29d02542] {
    width: 100%;
    height: 100vh;
    overflow: auto;
}
.btn[data-v-29d02542] {
    margin-bottom: 70px;
}