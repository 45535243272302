.bottom-nav[data-v-82dc7d4c] {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 50px;
    border-top: 2px solid #1bb5c1;
    background-color: #fff;
    box-shadow: 0px -1px 5px 0px rgba(153,153,153,1);
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.nav-link[data-v-82dc7d4c] {
    width: 30px;
    height: 30px;
}
.nav-link img[data-v-82dc7d4c] {
    width: 100%;
}
.mini-propic[data-v-82dc7d4c] {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    border: 1px solid #999;
    overflow: hidden;
}
.mini-propic img[data-v-82dc7d4c] {
    width: 100%;
    height: 100%;
    object-fit: cover;
}