.navigation-container[data-v-ee1725e1] {
    width: 100%;
    overflow: hidden;
    position: relative;
}
.preview[data-v-ee1725e1] {
    position: absolute;
    bottom: 0;
    left: 2px;
    right: 2px;
    border-radius: 10px 10px 0 0;
    background-color: #ffffff;
    height: 120px;
    padding: 10px;
    text-align: left;
    overflow: hidden;
    box-shadow: 1px 1px 5px 0px rgba(210, 210, 210, 1);

    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    transform: translate(0, 120px);
    -webkit-transform: translate(0, 120px);
}
.preview.open[data-v-ee1725e1] {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
}
.preview .col.s3[data-v-ee1725e1] {
    margin: 0;
    padding: 0;
}
.preview .col.s9[data-v-ee1725e1] {
    color: #999;
    font-size: 13px;
    padding-top: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.preview .titolo .s12[data-v-ee1725e1] {
    margin: 0;
    padding: 0;
}
.preview .immagine img[data-v-ee1725e1] {
    width: 65px;
    height: 65px;
    border-radius: 8px;
    object-fit: cover;
}
.row.titolo[data-v-ee1725e1] {
    margin-bottom: 7px;
    font-weight: bold;
    font-size: 13px;
    color: #1bb5c1;
}
.chiudi-preview[data-v-ee1725e1] {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
}
.chiudi-preview img[data-v-ee1725e1] {
    width: 100%;
    height: 100%;
}
.hidden-bar[data-v-ee1725e1] {
    display: none;
}