.no-buoni[data-v-b5324e6a]{
    border-radius: 7px;
    box-shadow: 1px 1px 5px 0px rgba(150,150,150,1);
    background-color: #fff;
    margin-bottom: 15px;
    height: 50px;
    text-align: center;
    padding-top: 13px;
    width: 100%;
}
.page-completa[data-v-b5324e6a] {
    width: 100%;
    height: calc(100vh - 50px);
    height: -webkit-calc(100vh - 50px);
}
.gratta-tab[data-v-b5324e6a] {
    width: 100%;
    height: calc(100% - 69px);
    height: -webkit-calc(100% - 69px);
    background-color: #1bb5c1;
    margin-top: 5px;
    overflow: auto;
    padding-bottom: 50px;
    -webkit-overflow-scrolling: touch;
}
.flexer-grattavinci[data-v-b5324e6a] {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
}
.flexer-grattavinci[data-v-b5324e6a]::after {
    content: "";
    width: 30%;
}
.flexer-grattavinci img[data-v-b5324e6a] {
    width: 30%;
    margin-bottom: 10px;
    height: auto;
}